import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import '../assets/css/home.css'
import BannerImg from '../assets/img/banner.gif'
import AvatarImg from '../assets/img/avatar.gif'

import axios from '../api/axios'

export default ({
}) => {
    const [discord, setDiscord] = React.useState({
        "banner": 'https://media1.tenor.com/m/yOV-KR0BbeQAAAAC/alya-sometimes-hides-her-feelings-in-russian-roshidere.gif',
        "avatar": 'https://media1.tenor.com/m/pZ7D-VgpMdIAAAAC/anime-fofo.gif',
        "username": "misaliba",
        "accentColor": "#2174ff",
        "primaryColor": "#ff0000"
    });
    return (
        <>
            <div className="home__container">
                <div className="home__container__content">
                    <div className="home__container__content__discord_card amn bottom" style={{ background: `linear-gradient(0deg, ${discord.accentColor}, ${discord.primaryColor})` }} data-animation='{"transform": "bottom", "last": true}'>
                        <div className="home__container__content__discord_card__content">
                            <div className="home__container__content__discord_card__content__header">
                                <div data-animation='{"transform": "left"}' className="home__container__content__discord_card__content__header__banner amn left">
                                    <img src={discord.banner} alt="banner" />
                                </div>
                                <div data-animation='{"transform": "left"}' className="home__container__content__discord_card__content__header__avatar amn left">
                                    <img src={discord.avatar} alt="avatar" />
                                </div>
                            </div>
                            <div className="home__container__content__discord_card__content__body">
                                <div className="home__container__content__discord_card__content__body__name">
                                    <h1 className="amn left" data-animation='{"transform": "left"}'>{discord.username}</h1>
                                </div>
                                <div className="home__container__content__discord_card__content__body__description">
                                    <p className="amn left" data-animation='{"transform": "left"}'>Hi, you can contact me on</p>
                                    <p className="amn left" data-animation='{"transform": "left"}'>Discord: <a href="https://discord.com/users/686582690597568520" target="_about">{discord.username}</a></p>
                                    <p className="amn left" data-animation='{"transform": "left"}'>Email: <a href="mailto:misaliba@michlip.eu" target="_about">misaliba@michlip.eu</a></p>
                                    <p className="amn left" data-animation='{"transform": "left"}'>Instagram: <a href="https://instagram.com/__misaliba__" target="_about">@__misaliba__</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
