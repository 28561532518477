/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import '../assets/css/footer.css';
export default () => {
	return (
		<>
			<div>
				<div className="footer">
					<div className="footer-divs">
						<div className="footer-div">
							<div className="SocialMedias">
								<a target="about" href="https://michlip.eu/discord">
									<div className="SocialMedia">
										<img
											className="SocialMedia-img"
											src="https://cdn.michlip.eu/svg/discord.svg"
										/>
									</div>
								</a>&nbsp;&nbsp;
								<a target="about" href="https://www.instagram.com/__misaliba__/">
									<div className="SocialMedia">
										<img className="SocialMedia-img" src="https://cdn.michlip.eu/svg/instagram.svg"></img>
									</div>
								</a>&nbsp;&nbsp;
								<a
									target="about"
									href="https://www.tiktok.com/@misaliba"
								>
									<div className="SocialMedia">
										<img
											className="SocialMedia-img"
											src="https://cdn.michlip.eu/svg/tiktok.svg"
										/>
									</div>
								</a>
							</div>
							&nbsp;&nbsp;Website made by misaliba
						</div>
						<div className="footer-div">
							misaliba.eu ©️ 2022 - {new Date().getFullYear()} All rights reserved.&nbsp;&nbsp;
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

