import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import '../assets/css/navbar.css'

export default ({
    animationStates,
    setAnimationStates,
    ready,
    setReady
}) => {
    const [[redirect, setRedirect], [redirectPath, setRedirectPath]] = [
        React.useState(false),
        React.useState("")
    ]
    const goTo = (path) => {
        if(redirectPath === path) return;
        setAnimationStates(0)
        setRedirectPath(path)
        setReady(0)
    }
    useEffect(() => {
        if(ready === 0 || ready === -1) return;
        setReady(-1)
        setRedirect(true)
        setTimeout(() => {
                setAnimationStates(1)
                console.log("redirected")
                setRedirect(false)
        }, 200)
    }, [ready])
    return (
        <>
        <div className="navbar">
            {redirect ? <Redirect to={redirectPath} /> : null}
            <div className="navbar__logo">
                <h1><a href="javascript:void(0)" onClick={() => goTo("/")}>misaliba.eu</a></h1>
            </div>
            <div className="navbar__links">
                <a href="javascript:void(0)" onClick={() => goTo("/")}>Home</a>
                <a href="javascript:void(0)" onClick={() => goTo("/projects")}>Projects</a>
                <a href="javascript:void(0)" onClick={() => goTo("/contact")}>Contact</a>
            </div>
            <div className="navbar__expander"></div>
        </div>

        </>
    )
}