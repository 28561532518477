import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import '../assets/css/home.css'
import BannerImg from '../assets/img/banner.gif'
import AvatarImg from '../assets/img/avatar.gif'

import axios from '../api/axios'

export default ({
}) => {
    console.log(BannerImg)
    const [discord, setDiscord] = React.useState({
        "banner": 'https://media.discordapp.net/attachments/845220553454518282/988496990310662154/caption.gif?ex=66e3cecf&is=66e27d4f&hm=164b6de0324aa439ec943e505b54f8c5383db53e9dd6249309fb1201d2533cfc&',
        "avatar": 'https://media1.tenor.com/m/pZ7D-VgpMdIAAAAC/anime-fofo.gif',
        "username": "misaliba",
        "accentColor": "#2174ff",
        "primaryColor": "#ff0000"
    });
    return (
        <>
            <div className="home__container">
                <div className="home__container__content">
                    <div className="home__container__content__discord_card amn bottom" style={{ background: `linear-gradient(0deg, ${discord.accentColor}, ${discord.primaryColor})` }} data-animation='{"transform": "bottom", "last": true}'>
                        <div className="home__container__content__discord_card__content">
                            <div className="home__container__content__discord_card__content__header">
                                <div data-animation='{"transform": "left"}' className="home__container__content__discord_card__content__header__banner amn left">
                                    <img src={discord.banner} alt="banner" crossOrigin="anonymous" />
                                </div>
                                <div data-animation='{"transform": "left"}' className="home__container__content__discord_card__content__header__avatar amn left">
                                    <img src={discord.avatar} alt="avatar" crossOrigin="anonymous" />
                                </div>
                            </div>
                            <div className="home__container__content__discord_card__content__body">
                                <div className="home__container__content__discord_card__content__body__name">
                                    <h1 className="amn left" data-animation='{"transform": "left"}'>{discord.username}</h1>
                                </div>
                                <div className="home__container__content__discord_card__content__body__description">
                                    <p className="amn left" data-animation='{"transform": "left"}'>Hi, I'm misaliba, a {new Date().getFullYear() - 2008} year old developer from the Czechia. I'm currently working on a Discord bot called <a target="_about" href="//arsenus.eu">Arsenus</a>.</p>
                                    <p className="amn left" data-animation='{"transform": "left"}'>When i become to be a developer i was around 10 and 11 years old. I started with Windows batch files and then i started with HTML, CSS and JS. I started with NodeJS when i was 13 years old. I started with Java when i was 14 years old.</p>
                                    <p className="amn left" data-animation='{"transform": "left"}'>My favourite programming language is TypeScript and my favourite framework is ReactTs.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
