import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/css/mainTheme.css'
import Navbar from './navbar';
import Footer from './footer';
import Home from '../pages/home';
import Projects from '../pages/projects';
import Contact from "../pages/contact";

export default () => {
    const [animationStates, setAnimationStates] = React.useState(-1);
    const [ready, setReady] = React.useState(-1);
    useEffect(() => {
        setTimeout(() => {
            setAnimationStates(1)
        }, 1500)
    }, [])
    useEffect(() => {
        const all = document.querySelectorAll('a');
        all.forEach((element, i) => {
            if (element.href === "javascript:void(0)") return;
            element.setAttribute("target", "_about")
        });
    }, [animationStates])

    useEffect(() => {
        const all = document.querySelectorAll('*[data-animation]');
        var interval = 200;
        var timeout = 0;
        all.forEach((element, i) => {
            const settings = JSON.parse(element.getAttribute('data-animation'));
            if (settings.transition) timeout += settings.transition;
            else timeout += interval;
        });
        const sorted = [];
        // sort all by it if element has to go last then it will be last
        all.forEach((element, i) => {
            const settings = JSON.parse(element.getAttribute('data-animation'));
            if (animationStates === 0) {
                if (settings.last)
                    sorted.push(element); else
                    sorted.unshift(element);
            } else if (animationStates === 1) {
                if (settings.last)
                    sorted.unshift(element); else
                    sorted.push(element);
            }
        });

        sorted.forEach((element, i) => {
            console.log(JSON.parse(element.getAttribute('data-animation')))
        });



        sorted.forEach((element, i) => {

            const settings = JSON.parse(element.getAttribute('data-animation'));
            if (animationStates === -1) {
                element.classList.add("amn");
                element.classList.add(settings.transform);
                return;
            }
            element.style.position = "relative";
            // element.style.display = "inline-block";
            element.style.transition = settings.transition ? settings.transition + "s" : "0.2s";
            if (animationStates === 1) { element.style.opacity = 0; }
            setTimeout(() => {
                if (animationStates === 0) {
                    element.style.transform = settings.transform === "left" ? "translateX(-10vw)" : settings.transform === "right" ? "translateX(10vw)" : settings.transform === "top" ? "translateY(-10vh)" : settings.transform === "bottom" ? "translateY(10vh)" : "translateX(-10vw)";
                    setTimeout(() => {
                        element.style.opacity = 0;
                    }, 100);
                } else if (animationStates === 1) {
                    element.style.opacity = 1;
                    setTimeout(() => {
                        element.style.transform = settings.transform === "left" ? "translateX(0vw)" : settings.transform === "right" ? "translateX(0vw)" : settings.transform === "top" ? "translateY(0vh)" : settings.transform === "bottom" ? "translateY(0vh)" : "translateX(0vw)";
                    }, 100);
                }
                setTimeout(() => {
                    element.classList.remove("amn");
                    element.classList.remove(settings.transform);
                    element.style.position = "";
                    element.style.display = "";
                    element.style.transition = "";
                }, settings.transition ? settings.transition + 100 : 300);
            }, i * interval)
        });
        console.log(timeout)
        setTimeout(() => {
            if (ready === -1) return;
            console.log("ready")
            setReady(1);
        }, 2000)

    }, [animationStates]);
    return (
        <>
            <ToastContainer />
            <Navbar animationStates={animationStates} setAnimationStates={setAnimationStates} ready={ready} setReady={setReady} />
            <Switch>
                <Route exact path="/">
                    <Home animationStates={animationStates} setAnimationStates={setAnimationStates} />
                </Route>
                <Route exact path="/projects">
                    <Projects animationStates={animationStates} setAnimationStates={setAnimationStates} />
                </Route>
                <Route exact path="/contact">
                    <Contact animationStates={animationStates} setAnimationStates={setAnimationStates} />
                </Route>

                <Route path="*">
                    <Home animationStates={animationStates} setAnimationStates={setAnimationStates} />
                </Route>
            </Switch>
            <Footer />
        </>
    )
}