import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import '../assets/css/projects.css'


export default ({
}) => {
    return (
        <>
            <div className="projects__container">
                <div className="projects__container__content">
                    <div className="projects">
                        <div className="project amn bottom" data-animation='{"transform": "bottom", "last": true}'>
                            <div className="project__header">
                                <h1 className="project__header__name amn left" data-animation='{"transform": "left"}'>Michlip</h1>
                                <span className="project__header__date amn right" data-animation='{"transform": "right"}'>2020 - Now</span>
                            </div>
                            <div className="project__body">
                                <p className="project__body__description amn left" data-animation='{"transform": "left"}'><a href="https://michlip.eu">Michlip.eu</a> is project which i started in 2020. It's a community for developers with a lot of features and subprojects for developers.</p>
                            </div>
                            <div className="project__footer">
                                <a href="https://michlip.eu" className="amn left" data-animation='{"transform": "left"}'>michlip.eu</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
